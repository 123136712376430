.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.hero-stl {
  background-image: url("../public/hero-bg.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.home-bg {
  background-position: center;
  /* padding: 15px; */
  background-image: url("../public/assets/webinor-home-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.top-con {
  display: flex;
  justify-content: center;

  background-color: #f00548;
  border-top-left-radius: 12px;
  border-bottom-right-radius: 12px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}
.hero-content {
  color: var(--e-global-color-text);
  font-size: 32px;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 42px;
}
.text-stl {
  color: var(--e-global-color-text);
  font-family: "Montserrat", Sans-serif;
  font-size: 25px;
  font-weight: 500;
}
.coach-overview {
  background-color: #04213f;
  border-radius: 8px;
  padding: 15px;
}
.txt-bg {
  background-color: #dbf4f4;
  height: 100%;
  padding: 15px;
  border-radius: 25px;
}

.txt-underline {
  position: relative;
}
.txt-underline::after {
  bottom: -10px;
  height: 5px;
  width: 250px;
  position: absolute;
  border-radius: 5px;
  left: 50%;
  transform: translateX(-50%);
  content: "";
  display: block;
  background: linear-gradient(45deg, #ff5c35, #ff977e, #ffd9d0, transparent);
}
.changeLife-txt-underline {
  position: relative;
}
.changeLife-txt-underline::after {
  bottom: -10px;
  height: 5px;
  width: 450px;
  position: absolute;
  border-radius: 5px;
  left: 47%;
  transform: translateX(-50%);
  content: "";
  display: block;
  background: linear-gradient(
    45deg,
    #ff5c35,
    #ff5c35,
    #ff977e,
    #ffd9d0,
    transparent
  );
}
.center-txt-underline {
  position: relative;
}
.center-txt-underline::after {
  bottom: -10px;
  height: 5px;
  width: 450px;
  position: absolute;
  border-radius: 5px;
  left: 43%;
  transform: translateX(-50%);
  content: "";
  display: block;
  background: linear-gradient(
    45deg,
    #ff5c35,
    #ff5c35,
    #ff977e,
    #ffd9d0,
    transparent
  );
}
.couldbe-txt-underline {
  position: relative;
}
.couldbe-txt-underline::after {
  bottom: -10px;
  height: 5px;
  width: 450px;
  position: absolute;
  border-radius: 5px;
  left: 51%;
  transform: translateX(-50%);
  content: "";
  display: block;
  background: linear-gradient(
    45deg,
    #ff5c35,
    #ff5c35,
    #ff977e,
    #ffd9d0,
    transparent
  );
}
.billing-details-bg {
  border-radius: 12px;
  background-color: #04213f;
  padding: 15px;
  
}
.title-bg {
  margin: auto;
  color: #fff;
  background: linear-gradient(45deg, #f00548, #ff5183, #ffabc3, transparent);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 24px;
  border-top-left-radius: 12px;
  border-bottom-right-radius: 12px;
  font-weight: 600;
}
.attend-container {
  margin-top: 5px;
  padding-left: 5%;
  padding-right: 10%;
  padding-top: 5px;
  /* padding-bottom: 5px; */
}
.section-bg {
  background-color: #04213f;
}
.yell-bg {
  background-color: #fef7dc;
}
.learn-bg {
  background: linear-gradient(0deg, rgba(4, 33, 63, 0.5), rgba(4, 33, 63, 0.5)),
    /* url(https://holistictherapistgayathri.com/wp-content/uploads/2024/01/bg2.webp) */
      url("../public/assets/acheive-bg.png");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.review-bg {
  padding: 15px;
  background-image: linear-gradient(
      0deg,
      rgba(31, 31, 31, 0.5),
      rgba(31, 31, 31, 0.5)
    ),
    url("../public/assets/review_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.bonus-bg {
  background: linear-gradient(
      0deg,
      rgba(109, 164, 176, 0.7),
      rgba(109, 164, 176, 0.7)
    ),
    /* url(https://holistictherapistgayathri.com/wp-content/uploads/2024/01/bg2.webp) */
      url(https://mapsystemsindia.com/images/resource/animation-for-business-development.jpg);
  /* background-image: url(#F00548); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.trans-bg {
  background-color: rgba(216, 216, 216, 0.7);
  border-radius: 12px;
  padding: 15px;
}
.trans-sub-bg {
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
.yell-btn {
  border: 1px solid transparent !important;
  border-radius: 50px !important;
  background-color: #f00548 !important;
  box-shadow: 0px 0px 10px 0px rgba(255, 213.999999) !important;
  text-align: center !important;

  font-family: "Montserrat", Sans-serif !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  margin-top: 25px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  color: white !important;
}
.footer-btn {
  border: transparent !important;
  border-radius: 20px !important;
  background-color: #f00548 !important;
  box-shadow: 0px 0px 10px 0px rgba(255, 213.999999) !important;
  text-align: center !important;
  font-family: "Montserrat", Sans-serif !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.coach-details-bg {
  margin-top: 15px;
  padding: 15px;
  background-color: #04213f;
  color: white;
}
.quotes-bg {
  background-color: #f7f7f7;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  padding: 25px;
}
.role-bg {
  background-color: #04213f;
  border-radius: 10px;
  padding: 10px;
  color: white;
}
.coach-img-bg {
  border-width: 4px 4px 4px 4px;

  border-radius: 10px 10px 10px 10px;
}
.faq-bg {
  border: 1px solid #d5d8dc;
}

.accordion-button:not(.collapsed) {
  color: white;
  font-family: "Montserrat", Sans-serif;
  font-weight: 600;
  background-color: #f00548;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
    var(--bs-accordion-border-color);
}
.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: black;
  font-family: "Montserrat", Sans-serif;
  font-weight: 600;
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
.accordion-item {
  color: black;
  font-family: "Montserrat", Sans-serif;
  font-weight: 500;
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid
    var(--bs-accordion-border-color);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
.footer-bg {
  background-color: "white";
  border-top: "1px solid #E7E7E7";
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.24);
  text-align: "center";
  padding: "20px";
  position: "fixed";
  left: "0";
  bottom: "0";
  width: "100%";
  display: "flex";
  justify-content: "space-around";
}
.f-content {
  display: flex;
}
@media (max-width: 768px) {
  .f-content {
    display: block;
  }
  .f-content h1 {
    font-size: 20px !important;
  }
  .f-content h3 {
    font-size: 14px !important;
  }
  .f-content p {
    font-size: 8px !important;
  }
  .footer-btn {
    border: transparent;
    border-radius: 20px;
    background-color: #f00548;
    box-shadow: 0px 0px 10px 0px rgba(255, 213.999999);
    text-align: center;
    font-family: "Montserrat", Sans-serif;
    font-size: 0px !important;
    font-weight: 700;
    padding-left: 10px;
    padding-right: 10px;
  }
  .footertime{
    width: 50%;
    justify-content: space-between;
    align-items: center;
  }
  .reg-para{
    font-size: 10px !important;
  }
}
.terms {
  margin-top: 10%;
  padding: 10px;
  box-shadow: 0 -4px 4px -4px rgba(0, 0, 0, 0.18);
}
.payment-btn {
  height: 60px;
  border-radius: 12px;
  margin-left: 2% !important;
  margin-right: 2% !important;
  border: 1px solid transparent;
  background-color: #f00548 !important;
  color: white;
  font-size: 18px;
  font-weight: "600px";
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.billing-details-bg input {
  width: 100% !important;
  height: 44px;
  border-radius: 0px;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  padding-left: 1rem;
}
.name {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}
.number {
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.row {
  margin-left: 0px;
  margin-right: 0px;
}

.name {
  width: 100%;
  height: 40px;
  border: 1px solid black;
  border-radius: 5px !important;
  margin-bottom: 1rem;
  padding-left: 1rem;
}
.react-tel-input .form-control {
  width: 100% !important;
  height: 40px !important;
}
@media (max-width:992px){
  .payDetails{
    padding-top: 1rem !important;
  }
  .reg-left{
    display: none !important;
  }
}
@media (max-width:768px){
  .title-bg{
    width: auto !important;
  }
  .journey-profile{
    display: block !important; 
  }
  .trans-sub-bg{
    background-color: transparent !important;
  }
  .footer-btn{
    border: transparent !important;
    border-radius: 10px !important;
    background-color: #f00548 !important;
    box-shadow: 0px 0px 10px 0px rgba(255, 213.999999) !important;
    text-align: center !important;
    font-family: "Montserrat", Sans-serif !important;
    font-size: 7px !important;
    font-weight: 600 !important;
    padding-left: 20px !important;
    padding-right: 20px !important ;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .strong{
    font-size: 14px !important;
  }
  .para-Foot{
    font-size: 10px !important;
  }

  .date-flex{
    justify-content: start !important;
    align-items: start !important;
  }
}
.date-flex{
  display: flex;
  justify-content: center;
  align-items: center;
}
.reg-left{
  margin-left: 10px;
  font-size: 18px;
}
.payDetails{
  padding-top: 6.5rem;
}
.title-bg{
  width: 50%;
}
.journey-profile{
  display: flex; 
}
